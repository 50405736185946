<template lang="pug">
  .candidates-dialog
    v-icon(small @click="toggleDialog").copy-arrow mdi-menu-down
    v-dialog(v-model="showing" :max-width="600")
      v-card
        v-card-title Queue date filters
        v-card-text
          datepicker(
            v-model="dialogFilters.queueExamDateAfter"
            label="Queue date from"
          )
          datepicker(
            v-model="dialogFilters.queueExamDateBefore"
            label="Queue date to"
          )
          v-checkbox(
            v-model="dialogFilters.queueExamDateStrict"
            label="Queue date strict"
            hide-details
          )
        v-card-actions
          v-spacer
          v-btn(color="error" text @click="toggleDialog") Close
          v-btn(color="success" text @click="submitQueue") Apply
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'

export default {
  name: 'queueDateFiltersDialog',

  mixins: [showingMixin],

  props: {
    filters: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    dialogFilters: {
      queueExamDateAfter: null,
      queueExamDateBefore: null,
      queueExamDateStrict: null
    }
  }),

  methods: {
    toggleDialog() {
      this.toggle()
      if (this.showing) this.initDialogFilters()
    },

    submitQueue() {
      this.toggle()
      this.$emit('change', this.dialogFilters)
    },

    initDialogFilters() {
      for (let filter in this.dialogFilters) {
        if (filter in this.filters) {
          this.dialogFilters[filter] = this.filters[filter]
        }
      }
    }
  },

  components: {
    datepicker: () => import('../../filters/CandidateDatepickerFilters.vue'),
  },
}
</script>
